import React, { useCallback, useEffect, useState } from 'react';

import { GraphQLClient, gql } from 'graphql-request';

import Post from '@/sections/post';

const query = gql`
  query AcademyPreviewQuery($id: String!) {
    academy(preview:true, id: $id) {
      title
      description
      poster {
        url
      }
      content {
        json
        links {
          assets {
            block {
              sys {
                id
              }
              url
              contentType
            }
          }
        }
      }
      asset {
        url
      }
    }
  }
`;

const AcademyPreview = ({ id }) => {
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleRequest = useCallback(async () => {
    const client = new GraphQLClient(`https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${process.env.GATSBY_CONTENTFUL_PREVIEW_TOKEN}`,
      },
    })

    const { academy } = await client.request(query, {
      id
    });

    setPost({ ...academy, contentful_id: id });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleRequest();
  }, []);

  return (
    <div>
      {isLoading ? <p>Loading...</p> : <Post pageContext={{ post }} />}
    </div>
  );
};

export default AcademyPreview;